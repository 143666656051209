.custom-radio-button {
	display: flex;
    justify-content: center;
    font-size: 25px;
}
.resultRadio label {
	display: inline-block;
    width: 40px;
    padding: 5px;
    border: solid 2px #ccc;
    transition: all 0.3s;
    border-radius: 45px;
    text-align: center;
}
  
.resultRadio input[type="radio"] {
	display: none;
}
  
.resultRadio input[type="radio"]:checked + label {
	border: solid 2px rgb(0, 0, 0);
	background-color: rgb(88, 236, 150);
}
.resultButton {
	display: none;
}
span:hover + .resultButton {
	display: inline-block;
}